<script>
import { debounce } from 'lodash'

export default {
  name: 'FilterListSearch',
  components: {
    Action: () => import('@/components/general/Action'),
    Icon: () => import('@/components/general/Icon'),
    InputField: () => import('@/components/general/InputField')
  },
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    hideErrorDetails: {
      type: Boolean,
      default: false
    },
    doSearch: {
      type: String,
      default: ''
    },
    prependLabel: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      open: false,
      search: null
    }
  },
  watch: {
    search: debounce(function () {
      this.$emit('search', this.search)
    }, 1000)
  },
  created () {
    if (this.doSearch) {
      this.open = true
      this.search = this.doSearch
    }
  },
  methods: {
    openSearchForm () {
      this.open = true
      this.$emit('tagAllFilters', false)
      this.$nextTick(() => {
        this.$refs.inputfield && this.$refs.inputfield.$refs.input.focus()
      })
    },
    closeSearchForm () {
      if (this.search && this.search.length) { return }
      this.open = false
      this.$emit('tagAllFilters', true)
    },
    searchValue () {
    },
    clearSearch () {
      this.search = null
      this.closeSearchForm()
    },
    label () {
      return this.prependLabel || this.$t('global:search')
    }
  }
}
</script>

<template>
  <div class="filter-item filter-search">
    <form
      ref="formSearch"
      :class="{'opened': open}"
      class="filter-search-form"
      @submit.prevent="searchValue()"
    >
      <InputField
        ref="inputfield"
        v-model="search"
        :dark="dark"
        :hide-error-details="hideErrorDetails"
        :prepend-label="label()"
        custom-prepend
        @blur="closeSearchForm"
        @focus="openSearchForm()"
        @prependAction="openSearchForm()"
      >
        <action
          slot="prepend"
          :class="{'dark': dark}"
          class="filter-item filter-item-link"
          icon="search"
          type="button"
          @click="openSearchForm()"
        />
        <action
          slot="button"
          class="filter-item filter-item-close"
          icon="close"
          type="button"
          @click="clearSearch()"
        />
      </InputField>
    </form>
  </div>
</template>

<style src="@/assets/styles/themes/default/filterlist.css"></style>
